<!-- @format -->

<template>
    <div class="gst-order-summary px-4">
        <div class="gst-order-summary__group-item d-flex flex-row px-0 py-4 py-md-5">
            <div class="flex xs8 u-text-transform-by-language">
                {{ $t('_common:terms.quantity') }}
            </div>
            <div class="flex xs4 text-right gst-order-summary__total-quantity">
                {{ totalQuantity }}
            </div>
        </div>
        <div
            v-for="(offer, index) in offersWithFees"
            :key="index"
            class="gst-order-summary__group-item px-0 pt-0 pb-4 pt-md-1 pb-md-6">
            <div
                v-for="(fee, index2) in offer.fees"
                :key="index2"
                class="gst-order-summary__item d-flex flex-row u-width-100 px-0 pt-4">
                <div
                    class="flex xs6 u-text-transform-by-language"
                    :class="{
                        'gst-order-summary__label-1': fee.isBase,
                        'gst-order-summary__label-2': !fee.isBase,
                    }">
                    {{ fee.label }}
                    <div
                        v-if="fee.isBase && shouldShowOriginalPrice"
                        class="gst-order-summary__label-4">
                        {{
                            `(${$t('_common:terms.originalPrice')}: ${formatOriginalResalePrice(originalPrice)} ${$t('_common:terms.perTicket')})`
                        }}
                    </div>
                </div>
                <div
                    :class="{
                        'gst-order-summary__label-3': fee.isBase,
                        'gst-order-summary__label-2': !fee.isBase,
                    }"
                    class="flex-grow-1 text-right font-weight-medium">
                    <span
                        v-if="!fee.showTotal"
                        class="gst-order-summary__label-2 font-weight-medium mr-2">
                        x{{ offer.quantity }}
                    </span>
                    <span
                        v-if="fee.marketPrice"
                        class="discounted-price mr-1">
                        {{
                            fee.showTotal
                                ? fee.totalMarketPrice
                                : fee.marketPrice | currencyFull(currency)
                        }}
                    </span>
                    {{
                        fee.showTotal ? fee.totalPrice : fee.totalItemPrice | currencyFull(currency)
                    }}
                </div>
            </div>
        </div>
        <div
            v-for="(orderFee, index) in orderWithFees"
            :key="'order' + index"
            class="gst-order-summary__group-item d-flex px-0 py-4 py-md-5"
            d-flex
            flex-column>
            <div class="flex xs6 u-text-transform-by-language">
                {{ orderFee.label }}
            </div>
            <div
                xs6
                class="gst-order-summary__label-3 flex xs6 text-right">
                {{ (orderFee.price + orderFee.tax) | currencyFull(currency) }}
            </div>
        </div>
        <div
            v-if="showShippingFeesSection"
            class="gst-order-summary__group-item px-0 py-4 py-md-5">
            <div class="d-flex">
                <div class="flex xs8 gst-order-summary__label-1 u-text-transform-by-language">
                    {{ $t('labels.shipping') }}
                </div>
                <div class="flex xs4 text-right font-weight-medium">
                    {{ shippingFee }}
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="flex xs8 gst-order-summary__label-2 u-text-transform-by-language">
                    {{ $t('labels.shippingTaxes') }}
                </div>
                <div class="flex xs4 text-right gst-order-summary__label-2">
                    {{ shippingTax }}
                </div>
            </div>
        </div>
        <div
            v-if="isLoyaltyTypeAllowRedeem"
            class="gst-order-summary__group-item px-0 py-4 py-md-5 d-flex align-center">
            <div class="flex xs6 gst-order-summary__label-1 u-text-transform-by-language">
                {{
                    isOrderConfirmation
                        ? $t('labels.bonusPointsRedeemPast')
                        : $t('labels.bonusPointsRedeem')
                }}
            </div>
            <div class="flex xs6 text-right gst-order-summary__total-price">
                {{ paymentModel.points | bonusPoints }}
            </div>
        </div>
        <div
            class="gst-order-summary__group-item px-0 py-4 py-md-5 d-flex flex-row align-start align-md-center">
            <div class="flex xs6">
                <div class="gst-order-summary__label-1 u-text-transform-by-language">
                    {{
                        isOrderConfirmation ? $t('labels.finalTotalPast') : $t('labels.finalTotal')
                    }}
                </div>
                <div
                    v-if="order.totalTaxes > 0"
                    class="gst-order-summary__label-2">
                    {{ $t('_common:terms.taxesOfIncluded', { tax: orderTax }) }}
                </div>
            </div>
            <div class="flex-grow-1 text-right u-white-space-nowrap">
                <span
                    v-if="showTotalMarketPrice"
                    class="discounted-price mr-1">
                    {{ totalMarketPrice | currencyFull(currency) }}
                </span>
                <span class="gst-order-summary__total-price">
                    {{ paymentModel.amount | currencyFull(currency) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import {
    cleanAndTranslate as feeUtilsCleanAndTranslate,
    groupItemsWithDiscounts,
} from '@core/utils/feeUtils';
import { sortCompare } from '@core/utils/sortCompareUtils';
import currencyShort from '@core/filters/currencyShort';
import OrderSummary from '@core/views/cart/TheCheckoutWizard/OrderSummary.vue?CORE';

export default {
    name: 'OrderSummary',
    extends: OrderSummary,
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.cart.theCheckoutWizard._components.containerItemPriceDetailCard',
    },
    computed: {
        orderTax() {
            return currencyShort(this.order.totalTaxes || 0, this.currency);
        },
        offersWithFees() {
            if (!this.offers.length) {
                return [];
            }

            return this.offers.map(({ name, quantity, charges }) => {
                return {
                    name,
                    quantity,
                    fees: groupItemsWithDiscounts(feeUtilsCleanAndTranslate(charges, name))
                        .map((item) => {
                            return {
                                ...item,
                                showTotal: !item.isBase,
                                totalMarketPrice: item.quantity * item.marketPrice,
                                totalItemPrice: item.price + item.tax,
                                totalPrice: item.quantity * (item.price + item.tax),
                            };
                        })
                        .sort((a, b) => sortCompare(a.order, b.order)),
                };
            });
        },
        totalMarketPrice() {
            let subtotal = 0;

            this.offersWithFees.forEach((offer) => {
                offer.fees.forEach((fee) => {
                    if (fee.marketPrice) {
                        subtotal += fee.totalMarketPrice;
                    } else if (fee.isBase) {
                        subtotal += fee.totalPrice;
                    }
                });
            });
            subtotal += this.order.totalTaxes;

            return subtotal;
        },
        showTotalMarketPrice() {
            return this.totalMarketPrice && this.totalMarketPrice > this.paymentModel.amount;
        },
    },
};
</script>
